<template>
  <v-app>
    <div class="row mt-1">
      <div class="col-12">
        <div class="card">

          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Candidate online course result
                </h4>
              </div>
            </div>
          </div>

          <div class="card-body">
            <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@5"
              >
              </v-skeleton-loader>

              <table v-if="!loading" class="table  table-vertical-center"
                      id="kt_advance_table_widget_4">
                  <thead>
                      <tr class="text-left">

                          <th class="px-3">Lesson</th>
                          <th class="px-3">Attempt ID</th>
                          <th class="px-3">Start time</th>
                          <th class="px-3">Completion time</th>
                          <th class="px-3">Mark obtained</th>
<!--                          <th class="px-3">Result date</th>-->
<!--                          <th class="pr-3 text-center">Options</th>-->
                      </tr>
                  </thead>
                  <tbody>
                      <template>
                          <tr v-if="examResults.length != 0" v-for="(item, index) in examResults" :key="index">
                            <td>
                              {{ item.lesson_title ? item.lesson_title : '--' }}
                            </td>
                            <td class="text-left">
                            {{ item.attempt_id }}
                          </td>

                          <td class="text-left">
                            {{ item.formatted_start_time  ? item.formatted_start_time : 'NA' }}
                          </td>
                          <td class="text-left">
                            {{ item.formatted_completion_time ? item.formatted_completion_time : 'NA' }}
                          </td>
                          <td class="text-left">
                            {{ item.mark_obtained ? item.mark_obtained : 'NA' }}
                          </td>
<!--                          <td class="text-left">-->
<!--                            {{ item.formatted_result_available_date ? item.formatted_result_available_date : '&#45;&#45;'  }}-->
<!--                          </td>-->
<!--                            <td class="pr-0 text-center">-->
<!--                              <template>-->
<!--                                <b-dropdown-->
<!--                                    size="sm"-->
<!--                                    variant="link"-->
<!--                                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"-->
<!--                                    no-caret-->
<!--                                    right-->
<!--                                    no-flip-->
<!--                                >-->
<!--                                  <template v-slot:button-content>-->
<!--                                    <i class="ki ki-bold-more-hor"></i>-->
<!--                                  </template>-->
<!--                                  &lt;!&ndash;begin::Navigation&ndash;&gt;-->
<!--                                  <div v-if="!item.is_official" class="navi navi-hover min-w-md-250px">-->
<!--                                    <b-dropdown-text tag="div" class="navi-item">-->
<!--                                      <a  class="navi-link">-->
<!--                                                  <span class="navi-icon">-->
<!--                                                      <v-icon color="darken-2">fas fa-edit</v-icon>-->
<!--                                                  </span>-->
<!--                                        <span class="navi-text">-->
<!--                                                      Mark as Complete-->
<!--                                                  </span>-->
<!--                                      </a>-->
<!--                                    </b-dropdown-text>-->
<!--                                  </div>-->

<!--                                  <div v-if="item.is_official" class="navi navi-hover min-w-md-250px">-->
<!--                                    <b-dropdown-text tag="div" class="navi-item">-->
<!--                                      <a  class="navi-link">-->
<!--                                                  <span class="navi-icon">-->
<!--                                                      <v-icon color="darken-2">fas fa-exclamation-triangle</v-icon>-->
<!--                                                  </span>-->
<!--                                        <span class="navi-text">-->
<!--                                                      Grant Second Official Exam Attempt-->
<!--                                                  </span>-->
<!--                                      </a>-->
<!--                                    </b-dropdown-text>-->

<!--                                    <b-dropdown-text tag="div" @click="viewMarksObtained(item)" class="navi-item">-->
<!--                                      <a  class="navi-link">-->
<!--                                                  <span class="navi-icon">-->
<!--                                                      <v-icon color="darken-2">fas fa-chart-line</v-icon>-->
<!--                                                  </span>-->
<!--                                        <span class="navi-text">-->
<!--                                                      View Marks Obtained-->
<!--                                                  </span>-->
<!--                                      </a>-->
<!--                                    </b-dropdown-text>-->

<!--                                    <b-dropdown-text tag="div" @click="viewFeedbackReport(item.id)" class="navi-item">-->
<!--                                      <a  class="navi-link">-->
<!--                                                  <span class="navi-icon">-->
<!--                                                      <v-icon color="darken-2">fas fa-file</v-icon>-->
<!--                                                  </span>-->
<!--                                        <span class="navi-text">-->
<!--                                                      View Feedback Report-->
<!--                                                  </span>-->
<!--                                      </a>-->
<!--                                    </b-dropdown-text>-->

<!--                                    <b-dropdown-text @click="printDigitalCertificate(item.id)" tag="div" class="navi-item">-->
<!--                                      <a  class="navi-link">-->
<!--                                                  <span class="navi-icon">-->
<!--                                                      <v-icon color="darken-2">fas fa-print</v-icon>-->
<!--                                                  </span>-->
<!--                                        <span class="navi-text">-->
<!--                                                      Print Digital Certificates-->
<!--                                                  </span>-->
<!--                                      </a>-->
<!--                                    </b-dropdown-text>-->

<!--                                    <b-dropdown-text @click="viewSupervisorInormation(item.id)" tag="div" class="navi-item">-->
<!--                                      <a  class="navi-link">-->
<!--                                                  <span class="navi-icon">-->
<!--                                                      <v-icon color="darken-2">fas fa-user</v-icon>-->
<!--                                                  </span>-->
<!--                                        <span class="navi-text">-->
<!--                                                      Supervisor Information-->
<!--                                                  </span>-->
<!--                                      </a>-->
<!--                                    </b-dropdown-text>-->

<!--                                    <b-dropdown-text tag="div" class="navi-item">-->
<!--                                      <a  class="navi-link">-->
<!--                                                  <span class="navi-icon">-->
<!--                                                      <v-icon color="darken-2">fas fa-sync</v-icon>-->
<!--                                                  </span>-->
<!--                                        <span class="navi-text">-->
<!--                                                      Resync Data with RS-->
<!--                                                  </span>-->
<!--                                      </a>-->
<!--                                    </b-dropdown-text>-->
<!--                                  </div>-->
<!--                                </b-dropdown>-->
<!--                              </template>-->
<!--                            </td>-->
                        </tr>

                          <tr v-if="examResults.length == 0">
                              <td colspan="7" class="text-center">
                                  No results found
                              </td>
                          </tr>
                      </template>
                  </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>

import CandidateService from "@/services/candidate/CandidateService";
import CandidateOnlineCourseResult from "@/services/candidate/online-course-result/CandidateOnlineCourseResult";

const candidate = new CandidateService();
const onlineCourseResult = new CandidateOnlineCourseResult();

export default {
    data(){
        return {
            examKey: '',
            candidate_id: '',
            examResults: [],
            loading: true,
            showMarksObtainedDialog: false,
            showSupervisorDialog: false,
            mark: null,
            availableMarks: null,
            remarks: '',
            candidate: '',
            examSupervisor: ''
        }
    },
    methods:{
        getExamKey(){
            this.examKey = this.$route.params.examKey;
        },
        getCandidateInfo(){
            candidate
            .getCandidateSummary(this.examKey)
            .then((response) => {
                this.candidate = response.data.candidate;
                this.candidate_id = response.data.candidate.id;
                this.getOnlineCourseResult();
            })
            .catch((err) => {

            })
            .finally(() => {
            })
        },
        getOnlineCourseResult(){
          this.loading = true;
            onlineCourseResult
            .getCourseResult(this.candidate_id)
            .then((response) => {
                this.examResults = response.data.examResult;
            })
            .catch((err) => {
              this.loading = false;
            })
            .finally(() => {
              this.loading = false;
            });
        },
        closeDialog(){
            this.showMarksObtainedDialog = false;
            this.showSupervisorDialog = false;
        },
    },
    mounted(){
        this.getExamKey();
        this.getCandidateInfo();
    }
};
</script>