import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidateOnlineCourseResult
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/candidate-online-course-result';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getByCandidate(id) {
        let url = `${this.#api}/get/by/candidate/${id}`
        return apiService.get(url)
    }

    getCourseResult(id){
        let url = `${this.#api}/get/by/candidate/${id}`
        return apiService.get(url)
    }
}
